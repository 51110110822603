import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import Rails from '@rails/ujs';
window.Rails = Rails;
Rails.start();

require("flatpickr")
require("select2")
